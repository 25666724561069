@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');

html {
  font-size: 100%; /* Default base size */
}

body, html, .App {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: 'Montserrat', sans-serif;
}

.background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2; /* Background is below everything */
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: url('./background_img.png') no-repeat center center fixed;
  background-size: cover; /* Ensure the background image covers the entire page */
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  opacity: 1;
  transition: opacity 1s ease; /* Smooth transition for fade effect */
}

.background-video.fade-out {
  opacity: 0;
}

.background-video.fade-in {
  opacity: 1;
}

/* Content (buttons) styling */
.content {
  position: absolute;
  right: 5%; /* Use percentage for responsiveness */
  top: 50%; /* Move to 50% of the screen */
  transform: translateY(-50%); /* Ensure buttons are centered vertically */
  z-index: 2; /* Ensure buttons are on top of the background */
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
}

/* Button size for 4K resolution */
.video-button {
  padding: 0;
  margin-bottom: 2vh;
  font-size: 0.9rem;
  font-weight: normal;
  color: white;
  background: url('./Transparent Button Connected Services.png') no-repeat center center;
  background-size: cover;
  border: none;
  cursor: pointer;
  transition: transform 0.2s ease, background 0.3s ease, color 0.3s ease;
  background-color: rgba(255, 255, 255, 0.1);
  width: 10vw;
  height: 3vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
}

.video-button.active {
  background: url('./Solid Button Connected Services.png') no-repeat center center;
  background-size: cover;
  color: #301A55;
}

.video-button:active {
  transform: scale(0.9);
}

/* Menu button (arrow) styling */
.menu-button {
  position: absolute;
  top: calc(50% - 20vh);
  right: 5%;
  width: 1.5vw;
  height: auto;
  z-index: 3;
  transition: transform 0.7s ease-in-out, opacity 2s ease-in-out; /* Reduce the transform transition duration */
  opacity: 1;
  transform: rotate(0deg); /* Initial state with no rotation */
}

.menu-button.rotated {
  transform: rotate(-90deg); /* Rotate 90 degrees left */
}





/* Hidden menu button with 15% opacity */
.menu-button.hidden {
  opacity: 0.10;
}

/* Progress Bar styling */
.progress-bar {
  position: absolute;
  bottom: 2%;
  left: 5%;
  width: 90%;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.progress-bar.visible {
  opacity: 1;
}

.progress-bar.hidden {
  opacity: 0;
}

/* Button visibility styles */
.content.hidden {
  opacity: 0;
  pointer-events: none;
}

.content.visible {
  opacity: 1;
}

/* Responsive button and font size based on screen width */
@media screen and (max-width: 2560px) {
  .video-button {
    width: 14vw;
    height: 4vh;
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 1440px) {
  .video-button {
    font-size: 0.7rem;
    width: 18vw;
    height: 5vh;
  }

  .menu-button {
    width: 2.5vw;
  }
}

@media screen and (max-width: 1024px) {
  .video-button {
    font-size: 0.7rem;
    width: 22vw;
    height: 6vh;
  }

  .menu-button {
    width: 3vw;
  }
}

@media screen and (max-width: 768px) {
  .video-button {
    font-size: 0.6rem;
    width: 26vw;
    height: 7vh;
  }

  .menu-button {
    width: 3.5vw;
  }
}
